import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import styled from "styled-components";

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
`;

export const StyledToggleButton = styled(ToggleButton)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  border-radius: 8px !important;
  border: none;

`;
