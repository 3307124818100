import { useMarketContext } from "@/context/MarketContext";
import { BASE_API_URL } from "@/utils/AppConfig";
import { getUiKitSymbol, renameKeys } from "@/utils/misc";
import useSWR, { Fetcher } from "swr";

type Record = {
  timestamp: number;
  value: number;
};

type BorrowAPYDataType = {
  symbol: string;
  data: Record[];
}[];

const fetcher: Fetcher<BorrowAPYDataType, string> = (url: string) =>
  fetch(url).then((r) => r.json());

const ACTION = "historical_lending_apy";

export default function useDepositAPY() {
  const { currentMarket } = useMarketContext();

  const API = `${BASE_API_URL}?action=${ACTION}&market=${currentMarket}`;
  
  const { data, isLoading, error } = useSWR(API, fetcher);

  const depositAPYData = (error || !Array.isArray(data)) ? {} : data?.reduce((acc, key) => ({
    ...acc,
    ...{ [getUiKitSymbol(key.symbol)]: key.data}
  }), {});

  return {
    depositAPYData,
    isLoading,
    error,
  };
}
