import TableCell from "@mui/material/TableCell";
import getCellStyles from "./helpers";
import { AssetData } from "../types";
import { TokenIcon } from "@sturdyfi/sturdy-ui-kit";
import { StyledCell, StyledTokenCell } from "./Styled";

interface CellProps {
  id: keyof AssetData;
  index: number;
  cellValue: string;
}

export default function Cell({ id, index, cellValue }: CellProps) {
  const position =
    id === "symbol" ? "left" : id === "utilizationRate" ? "right" : "middle";
  return (
    <TableCell
      key={`${id}-${index}-cell`}
      align={id === "symbol" ? "left" : "right"}
      style={getCellStyles(position)}
    >
      {id === "symbol" ? (
        <StyledTokenCell>
          <TokenIcon
            tokenSymbol={cellValue}
            // tokenFullName={cellValue.toUpperCase()}
            height={30}
            width={30}
          />
          {cellValue.toUpperCase()}
        </StyledTokenCell>
      ) : (
        <StyledCell>{cellValue}</StyledCell>
      )}
    </TableCell>
  );
}
