import { useMarketContext } from "@/context/MarketContext";
import { BASE_API_URL } from "@/utils/AppConfig";
import { renameKeys } from "@/utils/misc";
import useSWR, { Fetcher } from "swr";

type Record = {
  timestamp: number;
  value: number;
};

type LiquidationDataType = Record[];

const fetcher: Fetcher<LiquidationDataType, string> = (url: string) =>
  fetch(url).then((r) => r.json());

const ACTION = "historical_liquidations";

export default function useLiquidation() {
  const { currentMarket } = useMarketContext();

  const API = `${BASE_API_URL}?action=${ACTION}&market=${currentMarket}`;

  const { data, isLoading, error } = useSWR(API, fetcher);

  const formattedData =
    data && !Object.keys(data).includes("error") ? data : [];

  const liquidationEventData = {Events: formattedData};

  return {
    liquidationEventData,
    isLoading,
    error,
  };
}
