import { CustomMarket, useMarketContext } from "@/context/MarketContext";
import { Box, styled, BoxProps } from "@mui/material";
import { eth, stable } from "@/images";
import { colors } from "@/utils/design-system";
import { StyledToggleButtonGroup, StyledToggleButton } from "./Styled";

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: 'fit-content',
  padding: 8,
  borderRadius: 16,
  margin: '0 auto',
  background: theme.palette.mode === 'light' ? colors.base.white : colors.secondary[600],

}));

export default function MarketSwitch() {
  const { currentMarket, setCurrentMarket } = useMarketContext();

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newMarket: CustomMarket
  ) => {
    if (newMarket !== null) {
      setCurrentMarket(newMarket);
    }
  };

  return (
    <StyledBox>
      <StyledToggleButtonGroup
        color="primary"
        value={currentMarket}
        exclusive
        onChange={handleChange}
        aria-label="Market"
      >
        <StyledToggleButton value={CustomMarket.proto_all}>
          All Markets
        </StyledToggleButton>        
        <StyledToggleButton value={CustomMarket.proto_mainnet}>
          <img
            src={stable}
            width="22px"
            height="22px"
            alt="Stablecoin Market"
          />
          Stablecoin Markets
        </StyledToggleButton>
        <StyledToggleButton value={CustomMarket.proto_eth}>
          <img src={eth} width="22px" height="22px" alt="Ether Market" />
          Ethereum Markets
        </StyledToggleButton>
      </StyledToggleButtonGroup>
    </StyledBox>
  );
}
