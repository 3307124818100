import { useMarketContext } from "@/context/MarketContext";
import { BASE_API_URL } from "@/utils/AppConfig";
import { renameKeys } from "@/utils/misc";
import useSWR, { Fetcher } from "swr";

type Record = {
  timestamp: number;
  value: number;
};

type VolumeDataType = {
  historyTVL: Record[];
  historyBorrow: Record[];
  historySupply: Record[];
  historyCollateral: Record[];
};

const fetcher: Fetcher<VolumeDataType, string> = (url: string) =>
  fetch(url).then((r) => r.json());

const ACTION = "historical_volumn";

const RENAME_KEYS_MAP: { [key: string]: string } = {
  historyTVL: "TVL",
  historyBorrow: "Total Borrows",
  historySupply: "Total Supply",
  historyCollateral: "Total Collateral",
};

export default function useVolumn() {
  const { currentMarket } = useMarketContext();

  const API = `${BASE_API_URL}?action=${ACTION}&market=${currentMarket}`;

  const { data, isLoading, error } = useSWR(API, fetcher);

  const formattedData =
    data && !Object.keys(data).includes("error")
      ? data
      : {
          historyTVL: [],
          historyBorrow: [],
          historySupply: [],
          historyCollateral: [],
        };

  const volumeData = renameKeys(RENAME_KEYS_MAP, formattedData);

  return {
    volumeData,
    isLoading,
    error,
  };
}
