/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */

import { CollateralData, Order } from "./types";

// helpers for sorting
export const getFirstValueOfObj = (obj: any) => {
  if (typeof obj !== "object" || Array.isArray(obj)) {
    throw new Error("Input must be of type object and not an array");
  }

  const objKeyArr = Object.keys(obj);
  const firstKey = objKeyArr[0];
  return obj[firstKey];
};

// https://material-ui.com/components/tables/
export const descendingComparator = (
  a: CollateralData,
  b: CollateralData,
  orderBy: keyof CollateralData
) => {
  let aComparate = a[orderBy];
  let bComparate = b[orderBy];
  /**
   * if aComparate or bComparate is of type object,
   * use the first key of object a or object b,
   * and extract the first value to use as comparate
   */
  if (typeof b[orderBy] === "object" && !Array.isArray(b[orderBy])) {
    bComparate = getFirstValueOfObj(b[orderBy]);
  }

  if (typeof a[orderBy] === "object" && !Array.isArray(a[orderBy])) {
    aComparate = getFirstValueOfObj(a[orderBy]);
  }

  if (bComparate < aComparate) {
    return -1;
  }
  if (bComparate > aComparate) {
    return 1;
  }
  return 0;
};

export const getComparator = (order: Order, orderBy: keyof CollateralData) => {
  if (order !== "asc" && order !== "desc") {
    throw new Error("Order must be one of asc or desc");
  } else {
    return order === "desc"
      ? (a: CollateralData, b: CollateralData) => descendingComparator(a, b, orderBy)
      : (a: CollateralData, b: CollateralData) => -descendingComparator(a, b, orderBy);
  }
};

export const getSortedRows = (
  rows: CollateralData[],
  order: Order,
  orderBy: keyof CollateralData
) => {
  const comparator = getComparator(order, orderBy);

  const stabilizedThis: [CollateralData, number][] = rows.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const compare = comparator(a[0], b[0]);
    if (compare !== 0) return compare;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};
