import { createTheme } from "@mui/material";
import { colors } from '@/utils/design-system'

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

const GlobalSettings = {
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 744,
      laptop: 1180,
      desktop: 1440,
    },
  },
  typography: {
    fontFamily: ["Poppins", "Arial", "sans-serif"].join(","),
    h1: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 24,
      lineHeight: '20px',
      letterSpacing: "-0.001em",
    },
    h2: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 22,
      lineHeight: 1,
    },
    button: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 1.36,
    },
    caption: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '20px',
    },
  },
}

export const lightTheme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: colors.greys[100],
        },
      }      
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          padding: '16px',
          background: colors.base.white,
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
          boxSizing: 'border-box',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: colors.greys[300],
          borderRadius: '10px',
        }
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'separate',
          borderSpacing: '0px 16px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          borderBottom: 'none',
          color: colors.secondary[100],
        },
        body: {
          borderStyle: 'solid',
          borderWidth: '1px 0px 1px 0px',
          borderColor: colors.greys[300],
          color: colors.secondary[600],
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          border: 'none',
          color: colors.secondary[200],
          "&.Mui-selected": {
            color: colors.secondary[900],
            backgroundColor: colors.greys[100],
            border: `1px solid ${colors.greys[200]} !important`,
          },
          "&:hover": {
            color: colors.primary[650],
            backgroundColor: colors.primary[50],
            border: `1px solid ${colors.primary[200]} !important`
          }
        }
      }
    },
  },
  palette: {
    mode: "light",
    background: {
      default: colors.greys[100],
    },
    primary: {
      main: colors.primary[50],
    },
    secondary: {
      main: colors.greys[100],
    },
  },
  ...GlobalSettings,
});

export const darkTheme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: colors.secondary[800],
        },
      }      
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          padding: '16px',
          background: colors.secondary[700],
          boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
          boxSizing: 'border-box',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: colors.secondary[700],
          borderRadius: '10px',
        }
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'separate',
          borderSpacing: '0px 16px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          borderBottom: 'none',
          color: colors.secondary[100],
        },
        body: {
          background: colors.secondary[600],
          color: colors.greys[100],
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          border: 'none',
          color: colors.greys[600],
          "&.Mui-selected": {
            color: colors.base.white,
            backgroundColor: colors.secondary[500],
          },
          "&:hover": {
            color: colors.greys[600],
            backgroundColor: colors.secondary[500],
            border: `1px solid ${colors.secondary[300]} !important`
          }
        }
      }
    },
  },
  palette: {
    mode: "dark",
    primary: {
      main: colors.secondary[500],
    },
    secondary: {
      main: colors.secondary[700],
    },
    background: {
      default: colors.secondary[800],
      // paper: blueGrey["700"],
    },
  },
  ...GlobalSettings,
});

export const themes = {
  light: lightTheme,
  dark: darkTheme,
};