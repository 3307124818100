import React, { ReactNode, useContext, useEffect, useMemo } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { themes } from "@/themes";
import { ThemeProvider as MuiThemeProvider } from "@emotion/react";
import { CssBaseline, Theme } from "@mui/material";

interface ThemeContextProps {
  toggleTheme: () => void;
  currentTheme: Theme;
  isCurrentThemeDark: boolean;
}

interface ThemeProviderProps {
  children: ReactNode;
}

const ThemeContext = React.createContext({} as ThemeContextProps);

export function ThemeProvider({ children }: ThemeProviderProps) {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [darkMode, setDarkMode] = React.useState(prefersDarkMode);

  const theme = useMemo(
    () => (darkMode ? themes["dark"] : themes["light"]),
    [darkMode]
  );

  useEffect(() => {
    setDarkMode(prefersDarkMode);
  }, [prefersDarkMode]);

  const toggleTheme = () => {
    setDarkMode(!darkMode);
  };

  return (
    <ThemeContext.Provider
      value={{
        toggleTheme,
        currentTheme: theme,
        isCurrentThemeDark: darkMode,
      }}
    >
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
}

export const useThemeContext = () => useContext(ThemeContext);
