import { useState } from "react";
import { TableContainer, Table } from "@mui/material";
import buildTableComponents from "./buildTableComponents";
import { getSortedRows } from "./helpers";
import { HeadCell, CollateralData, Order } from "./types";

const headCells: readonly HeadCell[] = [
  {
    id: "symbol",
    numeric: false,
    disablePadding: true,
    label: "Assets",
    sortable: false,
  },
  {
    id: "supply",
    numeric: true,
    disablePadding: false,
    label: "Supply",
    sortable: true,
  },
  {
    id: "depositAPY",
    numeric: true,
    disablePadding: false,
    label: "Deposit APY",
    sortable: true,
  },
  {
    id: "maxLeverage",
    numeric: true,
    disablePadding: false,
    label: "Max.Leverage",
    sortable: true,
  },
  {
    id: "maxAPY",
    numeric: true,
    disablePadding: false,
    label: "Max.APY",
    sortable: true,
  },
];

interface CollateralTableProps {
  data: CollateralData[];
}

export default function CollateralTable({ data }: CollateralTableProps) {
  const { Head, Body } = buildTableComponents();
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof CollateralData>("supply");

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof CollateralData
  ) => {
    const newOrder = orderBy === property && order === "asc" ? "desc" : "asc";
    const newOrderBy = property;

    setOrder(newOrder);
    setOrderBy(newOrderBy);
  };

  return (
    <TableContainer>
      <Table>
        <Head
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <Body rows={getSortedRows(data, order, orderBy)} />
      </Table>
    </TableContainer>
  );
}
