import styled from "styled-components";
import { LabelsNormal, LabelsBold } from "@/utils/design-system/typography";

export const StyledCell = styled.span`
  ${LabelsNormal}
`;

export const StyledTokenCell = styled.div`
  ${LabelsBold}
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;