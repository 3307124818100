import { useMarketContext } from "@/context/MarketContext";
import { BASE_API_URL } from "@/utils/AppConfig";
import { renameKeys } from "@/utils/misc";
import useSWR, { Fetcher } from "swr";

type OverviewDataType = {
  total_value_locked: number;
  total_borrowed: number;
  total_supplied: number;
  total_collateral: number;
  total_liquidity_available: number;
  total_yield_generated: number;
};

const fetcher: Fetcher<OverviewDataType, string> = (url: string) =>
  fetch(url).then((r) => r.json());

const ACTION = "overview";

const RENAME_KEYS_MAP: { [key: string]: string } = {
  total_value_locked: "Total Value Locked",
  total_borrowed: "Total Supplied",
  total_supplied: "Total Borrowed",
  total_collateral: "Total Collateral",
  total_liquidity_available: "Total Liquidity Available",
  total_yield_generated: "Total Yield Generated",  
};

export default function useOverview() {
  const { currentMarket } = useMarketContext();

  const API = `${BASE_API_URL}?action=${ACTION}&market=${currentMarket}`;

  const { data, isLoading, error } = useSWR(API, fetcher);

  const formattedData =
    data && !Object.keys(data).includes("error") ? data : {
      total_value_locked: 0,
      total_borrowed: 0,
      total_supplied: 0,
      total_collateral: 0,
      total_liquidity_available: 0,
      total_yield_generated: 0,
    };

  const overviewData: { [x: string]: number} = renameKeys(RENAME_KEYS_MAP, formattedData);

  return {
    overviewData,
    isLoading,
    error,
  };
}
