import { useMarketContext } from "@/context/MarketContext";
import { BASE_API_URL } from "@/utils/AppConfig";
import { renameKeys } from "@/utils/misc";
import useSWR, { Fetcher } from "swr";

type Record = {
  timestamp: number;
  value: number;
};

type UserDataType = {
  total: Record[];
};

const fetcher: Fetcher<UserDataType, string> = (url: string) =>
  fetch(url).then((r) => r.json());

const ACTION = "historical_user";

const RENAME_KEYS_MAP: { [key: string]: string } = {
  total: "Total Users",
  lenders: "Lenders",
  borrowers: "Borrowers",
};

export default function useUser() {
  const { currentMarket } = useMarketContext();

  const API = `${BASE_API_URL}?action=${ACTION}&market=${currentMarket}`;

  const { data, isLoading, error } = useSWR(API, fetcher);

  const formattedData =
    data && !Object.keys(data).includes("error")
      ? data
      : {
          total: [],
          lenders: [],
          borrowers: [],
        };

  const userData = renameKeys(RENAME_KEYS_MAP, formattedData);

  return {
    userData,
    isLoading,
    error,
  };
}
