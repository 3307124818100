import React from "react";
import {
  Box,
  IconButton,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  styled,
} from "@mui/material";
import { Button } from "../Button";
import { useThemeContext } from "@/context/ThemeContext";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import NightlightOutlinedIcon from "@mui/icons-material/NightlightOutlined";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import { colors } from "@/utils/design-system";

const LightAndDark = styled(Switch)(({ theme }) => ({
  width: 56,
  height: 28,
  padding: 0,
  "& .MuiSwitch-track": {
    width: 56,
    height: 28,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark" ? colors.primary[800] : colors.primary[50],
    borderRadius: 16,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 20,
      height: 20,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        colors.greys[100]
      )}" d="M14 4c.34 0 .68.02 1.01.07C13.1 6.23 12 9.05 12 12s1.1 5.77 3.01 7.93c-.33.05-.67.07-1.01.07-4.41 0-8-3.59-8-8s3.59-8 8-8m0-2C8.48 2 4 6.48 4 12s4.48 10 10 10c1.82 0 3.53-.5 5-1.35-2.99-1.73-5-4.95-5-8.65s2.01-6.92 5-8.65C17.53 2.5 15.82 2 14 2z"/></svg>')`,
      left: 4,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        colors.primary[600]
      )}" d="m6.76 4.84-1.8-1.79-1.41 1.41 1.79 1.79zM1 10.5h3v2H1zM11 .55h2V3.5h-2zm8.04 2.495 1.408 1.407-1.79 1.79-1.407-1.408zm-1.8 15.115 1.79 1.8 1.41-1.41-1.8-1.79zM20 10.5h3v2h-3zm-8-5c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm0 10c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm-1 4h2v2.95h-2zm-7.45-.96 1.41 1.41 1.79-1.8-1.41-1.41z" /></svg>')`,
      right: 4,
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 0,
    "&.Mui-checked": {
      transform: "translateX(28px)",
      "& .MuiSwitch-thumb": {
        color: colors.base.white,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor:
          theme.palette.mode === "dark"
            ? colors.primary[800]
            : colors.primary[50],
      },
    },
  },
  "& .MuiSwitch-thumb": {
    color: colors.base.white,
    boxShadow: "none",
    width: 20,
    height: 20,
    margin: 4,
  },
}));

export default function ThemeSwitch() {
  const { isCurrentThemeDark, toggleTheme } = useThemeContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    toggleTheme();
  };

  return (
    <Box>
      <LightAndDark checked={isCurrentThemeDark} onChange={handleChange} />
    </Box>
  );
}
