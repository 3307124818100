import { useMarketContext } from "@/context/MarketContext";
import { BASE_API_URL } from "@/utils/AppConfig";
import { getUiKitSymbol, renameKeys } from "@/utils/misc";
import useSWR, { Fetcher } from "swr";

type AssetInfo = {
  asset: string;
  supply: number;
  borrow: number;
  deposit_apy: number;
  borrow_apy: number;
  utilization_rate: number;
};

type ResponseType = AssetInfo[];

const fetcher: Fetcher<ResponseType, string> = (url: string) =>
  fetch(url).then((r) => r.json());

const ACTION = "assets_lend_borrow";

const RENAME_KEYS_MAP: { [key: string]: string } = {
  asset: "symbol",
  deposit_apy: "depositAPY",
  borrow_apy: "borrowAPY",
  utilization_rate: "utilizationRate",
};

export default function useAssets() {
  const { currentMarket } = useMarketContext();

  const API = `${BASE_API_URL}?action=${ACTION}&market=${currentMarket}`;

  const { data, isLoading, error } = useSWR(API, fetcher);

  const formattedData =
    data && !Object.keys(data).includes("error") ? data : [];

  const assetData = formattedData?.map((ele) => {
    return {
      symbol: getUiKitSymbol(ele.asset),
      supply: ele.supply,
      borrow: ele.borrow,
      depositAPY: ele.deposit_apy,
      borrowAPY: ele.borrow_apy,
      utilizationRate: ele.utilization_rate,
    };
  });

  return {
    assetData,
    isLoading,
    error: error || Object.keys(data || []).includes("error"),
  };
}
