import {
  Toolbar,
  AppBar,
  Box,
  Button,
  styled,
  Typography,
} from "@mui/material";
import { colors } from "@/utils/design-system";
import { useThemeContext } from "@/context/ThemeContext";
import SturdyIcon from "@/components/Icons/SturdyIcon";
import ThemeSwitch from "@/components/ThemeSwitch";

export default function Header() {
  const { isCurrentThemeDark } = useThemeContext();

  const iconColor = isCurrentThemeDark ? colors.greys[100] : colors.secondary[800];
  const textColor = isCurrentThemeDark ? colors.greys[100] : colors.secondary[200];
  const buttonColor = isCurrentThemeDark ? colors.greys[300] : colors.primary[600];

  const displayDesktop = () => {
    return (
      <Toolbar
        sx={{
          width: "100%",
          maxWidth: "desktop",
          mx: "auto",
          px: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 0.5,
            }}
          >
            <SturdyIcon color={iconColor}/>
            <Typography variant="h1" component="span" color={textColor}>Dashboard</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 2,
            }}
          >
            <ThemeSwitch />
            <Button
              href="https://app.sturdy.finance/"
              variant="contained"
              target="_blank"
            >
              <Typography variant="button" component="span" color={buttonColor} textTransform="capitalize">Goto DAPP</Typography>
            </Button>
          </Box>
        </Box>
      </Toolbar>
    );
  };

  return (
    <header>
      <AppBar>{displayDesktop()}</AppBar>
    </header>
  );
}
