import { Box, Typography, styled } from "@mui/material";
import { ReactNode, useState } from "react";
import { VisibilityOutlined, VisibilityOffOutlined } from "@mui/icons-material";
import { colors } from "@/utils/design-system";
import { Captions1 } from "@/utils/design-system/typography";
import { useThemeContext } from "@/context/ThemeContext";

interface SubHeaderProps {
  title: string;
  showVisibility?: boolean;
  children: ReactNode;
}

export default function SectionWrapper({
  title,
  showVisibility = true,
  children,
}: SubHeaderProps) {
  const { isCurrentThemeDark } = useThemeContext();
  const [visibility, setVisibility] = useState(true);
  const titleColor = isCurrentThemeDark ? colors.greys[100] : colors.secondary[900];

  return (
    <Box
      sx={{
        my: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h2" textTransform="capitalize" color={titleColor}>
          {title}
        </Typography>
        {showVisibility && (
          <div onClick={() => setVisibility(!visibility)}>
            {visibility ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
          </div>
        )}
      </Box>
      <Box sx={{ display: visibility ? "block" : "none"}}>
        {children}
      </Box>
    </Box>
  );
}
