import { useCallback, useEffect } from "react";
import Charts from "react-apexcharts";
import ApexCharts from "apexcharts";
import { useThemeContext } from "@/context/ThemeContext";
import { useIntl } from "react-intl";

type PeroidOptionDataType = {
  label: string;
  timespan: number; // terms of days
};

const ONE_DAY_IN_MS = 86400 * 1000;

export const PERIOD_NAMES = ["1W", "1M", "3M", "1Y", "ALL"] as const;

export type PeriodType = typeof PERIOD_NAMES[number];

export const PERIOD_OPTIONS: { [key in PeriodType]: PeroidOptionDataType } = {
  "1W": {
    label: "1W",
    timespan: 7,
  },
  "1M": {
    label: "1M",
    timespan: 30,
  },
  "3M": {
    label: "3M",
    timespan: 90,
  },
  "1Y": {
    label: "1Y",
    timespan: 365,
  },
  ALL: {
    label: "ALL",
    timespan: 0,
  },
};

export type ChartFormat = 'currency' | 'percentage' | 'number';

type ChartDataType = {
  name: string;
  data: [number, number][];
};
interface CustomChartProps {
  chartId: string;
  series: ChartDataType[];
  dataColors: string[];
  firstTimestamp: number;
  lastTimestamp: number;
  period?: PeriodType;
  format?: ChartFormat;
}

export default function CustomChart({
  chartId,
  series,
  dataColors,
  firstTimestamp,
  lastTimestamp,
  period = "ALL",
  format = 'currency',
}: CustomChartProps) {
  const intl = useIntl();
  const { isCurrentThemeDark } = useThemeContext();

  const parsedObject = JSON.stringify(series);

  const getPriorTimestamp = (period: PeriodType, baseTimestamp: number) => {
    if (period === "ALL") {
      return firstTimestamp;
    }
    return baseTimestamp - PERIOD_OPTIONS[period].timespan * ONE_DAY_IN_MS;
  };

  useEffect(() => {
    const mode = isCurrentThemeDark ? "dark" : "light";
    ApexCharts.exec(chartId, "updateOptions", {
      theme: {
        mode: mode,
      },
      tooltip: {
        theme: mode,
      },
    });
  }, [chartId, isCurrentThemeDark]);

  useEffect(() => {
    const endTime = lastTimestamp;
    const startTime = getPriorTimestamp(period, endTime);
    ApexCharts.exec(chartId, "zoomX", startTime, endTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartId, parsedObject, period, lastTimestamp, firstTimestamp]);

  const valueFormat = useCallback((value: number, isAxis?: boolean) => {
    if (format === 'currency') {
      return intl.formatNumber(Number(value), {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: isAxis ? 0 : 2,
      });
    }
    if (format === 'percentage') {
      return intl.formatNumber(Number(value), {
        style: "percent",
        maximumFractionDigits: isAxis ? 0 : 2,
      });
    }
    return value.toString();
  }, [format]);

  return (
    <div style={{ width: "100%" }}>
      <Charts
        type="area"
        width={"100%"}
        height={"400px"}
        options={{
          chart: {
            id: chartId,
            type: "area",
            toolbar: {
              show: false,
            },
            zoom: {
              // autoScaleYaxis: true,
            },
          },
          colors: dataColors,
          dataLabels: {
            enabled: false,
          },
          legend: {
            show: false,
          },
          markers: {
            size: 0,
          },
          xaxis: {
            type: "datetime",
          },
          yaxis: {
            labels: {
              formatter: (value) => {
                return valueFormat(value, true);
              },
            },
            // decimalsInFloat: 2,
          },
          tooltip: {
            enabled: true,
            x: {
              format: "dd MMM yyyy",
            },
            y: {
              formatter: function (
                value,
                { series, seriesIndex, dataPointIndex, w }
              ) {
                return valueFormat(value);
              },
            },
          },
        }}
        series={series}
      />
    </div>
  );
}
