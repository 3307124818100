import { Container, Link, Paper, Typography } from "@mui/material";
import SocialDiscordIcon from "../Icons/SocialDiscordIcon";
import { useThemeContext } from "@/context/ThemeContext";
import { colors } from "@/utils/design-system";
import SocialTelegramIcon from "../Icons/SocialTelegramIcon";
import SocialGithubcon from "../Icons/SocialGithubcon";
import { StyledBox, StyledSocialIcons } from "./Styled";

export default function Footer() {
  const { isCurrentThemeDark } = useThemeContext();
  const textColor = isCurrentThemeDark ? colors.greys[100] : "#000000";
  const iconColor = isCurrentThemeDark
    ? colors.secondary[300]
    : colors.greys[600];

  return (
    <Paper component="footer" square variant="outlined">
      <Container maxWidth="desktop" sx={{ py: 2, px: 5 }}>
        <StyledBox>
          <Typography variant="caption" color={textColor}>
            ©2023 Sturdy Finance Dashboard
          </Typography>
          <StyledSocialIcons>
            <Link href="https://t.me/sturdyfinance" target="_blank">
              <SocialTelegramIcon width={24} height={24} color={iconColor} />
            </Link>
            <Link href="https://discord.gg/tRVHp6Vx5N" target="_blank">
              <SocialDiscordIcon width={24} height={24} color={iconColor} />
            </Link>
            <Link href="https://github.com/sturdyfi" target="_blank">
              <SocialGithubcon width={24} height={24} color={iconColor} />
            </Link>
          </StyledSocialIcons>
        </StyledBox>
      </Container>
    </Paper>
  );
}
