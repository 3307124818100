import { useEffect, useMemo, useState } from "react";
import { ToggleButtonGroup, ToggleButton, Box } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { useThemeContext } from "@/context/ThemeContext";
import { colors } from "@/utils/design-system";
import Chart, {
  ChartFormat,
  PERIOD_NAMES,
  PERIOD_OPTIONS,
  PeriodType,
} from "../Chart";
import FilterChip from "../FilterChip";
import { StyledBox, StyledLeftPanel, StyledRightPanel } from "./Styled";
import { useMarketContext } from "@/context/MarketContext";

type ItemDataType = {
  selected: boolean;
  color: string;
};

interface ChartWrapperProps {
  chartName: string;
  marketData: {
    [key: string]: { timestamp: number; value: number }[];
  };
  chartFormat?: ChartFormat;
}

const CIRCLE_COLORS = [
  "#4169E1",
  "#89CFF0",
  "#4CBB17",
  "#FF4500",
  "#FFD300",
  "#FF1493",
  "#4B0082",
  "#1C1C1C",
  "#40E0D0",
  "#BCB88A",
];

export default function ChartWrapper({
  chartName,
  marketData,
  chartFormat,
}: ChartWrapperProps) {
  const { isCurrentThemeDark } = useThemeContext();
  const { currentMarket } = useMarketContext();
  const [items, setItems] = useState(new Map<string, ItemDataType>());
  const [period, setPeriod] = useState<PeriodType>("ALL");

  const bgColor = isCurrentThemeDark
    ? colors.secondary[600]
    : colors.base.white;
  const borderColor = isCurrentThemeDark
    ? colors.secondary[600]
    : colors.greys[300];

  const leftBgColor = isCurrentThemeDark
    ? colors.secondary[700]
    : colors.greys[100];
  const leftBorderColor = isCurrentThemeDark
    ? colors.secondary[700]
    : "#e1e1e1";

  const jsonDataKeys = JSON.stringify(Object.keys(marketData)) + currentMarket;

  useEffect(() => {
    const labels = Object.keys(marketData);
    const initialValues: [string, ItemDataType][] = labels.map(
      (label, index) => [
        label,
        { selected: true, color: CIRCLE_COLORS[index % CIRCLE_COLORS.length] },
      ]
    );
    setItems(new Map(initialValues));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonDataKeys]);

  const timespan = useMemo(() => {
    return Object.keys(marketData)
      .map((key) => {
        const x = marketData[key];
        const first =
          x.length > 0 ? x[0].timestamp * 1000 : Number.MAX_SAFE_INTEGER;
        const last = x.length > 0 ? x.slice(-1)[0].timestamp * 1000 : 0;
        return [first, last];
      })
      .reduce(
        (acc, x) => {
          const first = Math.min(acc[0], x[0]);
          const last = Math.max(acc[1], x[1]);
          return [first, last];
        },
        [Number.MAX_SAFE_INTEGER, 0]
      ) as [number, number];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonDataKeys]);

  const totalDataSeries = useMemo(() => {
    const unitDecimals = chartFormat === "number" ? 0 : 2;
    const convertUnit = 10 ^ unitDecimals;

    return Object.keys(marketData).map((label) => {
      const name = label;
      const data = marketData[label].map(({ timestamp, value }) => {
        const roundedValue = (
          Math.round(value * convertUnit) / convertUnit
        ).toFixed(unitDecimals);
        const newValue =
          chartFormat === "percentage" ? +roundedValue / 100 : +roundedValue;

        return [timestamp * 1000, newValue];
      }) as [number, number][];

      return {
        name,
        data,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonDataKeys, chartFormat]);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newSelection: PeriodType
  ) => {
    setPeriod(newSelection);
  };

  const updateFilter = (k: string) => {
    const v = items.get(k);
    if (v) {
      setItems(
        new Map(
          items.set(k, {
            ...v,
            selected: !v?.selected,
          })
        )
      );
    }
  };

  const keysOfData = Object.keys(marketData);
  const dataColors = keysOfData
    .filter((label) => {
      const settings = items.get(label);
      return settings?.selected;
    })
    .map((label) => {
      const settings = items.get(label);
      return settings?.color || CIRCLE_COLORS[0];
    });

  const series = totalDataSeries.filter((xs) => {
    const name = xs.name;
    const settings = items.get(name);
    return settings?.selected;
  });

  return (
    <StyledBox bgColor={bgColor} borderColor={borderColor}>
      <StyledLeftPanel bgColor={leftBgColor} borderColor={leftBorderColor}>
        {keysOfData.map((key) => (
          <FilterChip
            key={key}
            label={key}
            circleColor={items.get(key)?.color || CIRCLE_COLORS[0]}
            selected={items.get(key)?.selected || false}
            handleClick={() => updateFilter(key)}
          />
        ))}
      </StyledLeftPanel>
      <StyledRightPanel>
        <ToggleButtonGroup
          color="primary"
          value={period}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          {PERIOD_NAMES.map((key: PeriodType) => {
            const option = PERIOD_OPTIONS[key];
            return (
              <ToggleButton key={key} value={key}>
                <CircleIcon />
                {option.label}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
        <Box sx={{ width: "100%" }}>
          {series && timespan && (
            <Chart
              chartId={chartName}
              series={series}
              dataColors={dataColors}
              firstTimestamp={timespan[0]}
              lastTimestamp={timespan[1]}
              period={period}
              format={chartFormat}
            />
          )}
        </Box>
      </StyledRightPanel>
    </StyledBox>
  );
}
