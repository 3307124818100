import { useIntl } from "react-intl";
import { TableRow } from "@mui/material";
import { AssetData } from "../types";
import Cell from "./Cell";

interface RowProps {
  index: number;
  asset: AssetData;
}

export default function Row({ index, asset }: RowProps) {
  const intl = useIntl();

  const formatValue = (id: keyof AssetData, value: string | number) => {
    if (id === 'supply' || id === 'borrow') {
      return intl.formatNumber(Number(value), {
        style: 'currency',
        currency: "USD",
        maximumFractionDigits: 2,
      });
    }
    if (id === "depositAPY" || id === "borrowAPY" || id === "utilizationRate") {
      return intl.formatNumber(Number(value) / 100, {
        style: "percent",
        maximumFractionDigits: 2,
      });
    }
    return String(value);
  };
  return (
    <TableRow key={`${index}-row`}>
      {
        (Object.keys(asset) as (keyof AssetData)[]).map((k) => (
          <Cell key={k} id={k} index={index} cellValue={formatValue(k, asset[k])} />
        ))
      }
    </TableRow>
  );
}
