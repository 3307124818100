import { useMarketContext } from "@/context/MarketContext";
import { BASE_API_URL } from "@/utils/AppConfig";
import { getUiKitSymbol, renameKeys } from "@/utils/misc";
import { getAssetInfo } from "@sturdyfi/sturdy-ui-kit";
import useSWR, { Fetcher } from "swr";

type RiskParams = {
  symbol: string;
  max_ltv: number;
  liquidation_threshold: number;
  liquidation_penalty: number;
  allowed_contract: string;
};

type ResponseType = RiskParams[];

const fetcher: Fetcher<ResponseType, string> = (url: string) =>
  fetch(url).then((r) => r.json());

const ACTION = "risk_parameter";

const ALLOWED_CONTRACTS = {
  curve: "Convex",
  balancer: "Aura",
  lido: "Lido",
};

export default function useRiskParams() {
  const { currentMarket } = useMarketContext();

  const API = `${BASE_API_URL}?action=${ACTION}&market=${currentMarket}`;

  const { data, isLoading, error } = useSWR(API, fetcher);

  const formattedData =
    data && !Object.keys(data).includes("error") ? data : [];

  const riskParamsData = formattedData?.map((ele) => {
    const symbol = getUiKitSymbol(ele.symbol);
    const asset = getAssetInfo(symbol);
    const assetName = asset.name.toLowerCase();
    const allowedContract = (
      Object.keys(ALLOWED_CONTRACTS) as Array<keyof typeof ALLOWED_CONTRACTS>
    )
      .map((key: keyof typeof ALLOWED_CONTRACTS) => {
        return assetName.includes(key.toLowerCase())
          ? ALLOWED_CONTRACTS[key]
          : "";
      })
      .reduce((acc, k) => acc + k, "");

    return {
      symbol,
      maxLTV: ele.max_ltv,
      liquidationThreshold: ele.liquidation_threshold,
      liquidationPenalty: ele.liquidation_penalty,
      allowedContract,
    };
  });

  return {
    riskParamsData,
    isLoading,
    error: error || Object.keys(data || []).includes("error"),
  };
}
