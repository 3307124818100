// FIXME: Update this configuration file based on your project information

export const AppConfig = {
  site_name: "Sturdy",
  title: "Sturdy",
  description:
    "Sturdy is a lending protocol where borrowers farm with up to 10x leverage & lenders receive high yields.",
  locale: "en",
};

export const BASE_API_URL='https://us-central1-stu-dashboard-a0ba2.cloudfunctions.net/getAnalyticsDashboard';