export const colors = {
  primary: {
    50: "#E9F1FE",
    60: "#D8E3FA",
    100: "#C7CFED",
    200: "#AFBDED",
    300: "#98AAED",
    400: "#8098ED",
    500: "#6885ED",
    600: "#4B71F9",
    650: "#335BEB",
    700: "#1B40C6",
    800: "#0C2788",
  },
  secondary: {
    100: "#9193A1",
    200: "#6C7087",
    300: "#4D526E",
    400: "#3B4366",
    500: "#323754",
    600: "#282F4A",
    700: "#252B43",
    800: "#1D233B",
    900: "#111422",
  },
  greys: {
    100: "#F7F9FB",
    200: "#EDF0F2",
    300: "#E1E4E5",
    400: "#D5D8D9",
    500: "#C8CBCC",
    600: "#BCBEBF",
  },
  success: {
    100: "#E5F4EE",
    200: "#AED9C8",
    300: "#8FD9BC",
    400: "#64D9AB",
    500: "#38D99A",
    600: "#0CD988",
    700: "#008840",
  },
  warning: {
    100: "#F8EFE4",
    200: "#F8E8D5",
    300: "#F8DDBC",
    400: "#F8C68B",
    500: "#F8AF59",
    600: "#F89828",
    700: "#AF5C00",
  },
  error: {
    100: "#FEEFF1",
    200: "#FEE0E4",
    300: "#FECED5",
    400: "#FE9BA9",
    500: "#FE687E",
    600: "#FE3653",
    700: "#E30840",
  },
  base: {
    white: "#fefefe",
    black: "#010101",
  },
};
