type CellStyleType = {
  borderTopLeftRadius?: string;
  borderBottomLeftRadius?: string;
  borderTopRightRadius?: string;
  borderBottomRightRadius?: string;
  borderLeftWidth?: string;
  borderRightWidth?: string;
  textAlign: 'left' | 'right';
};

const getCellStyles = (
  position: 'left' | 'middle' | 'right'
): CellStyleType => {
  let styles: CellStyleType = {
    // paddingLeft: "0rem",
    // paddingRight: "0rem",
    // verticalAlign: 'top',
    textAlign: 'right',
  };

  if (position === 'left') {
    styles = {
      ...styles,
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
      borderLeftWidth: '1px',
      textAlign: 'left',
    }
  }

  if (position === 'right') {
    styles = {
      ...styles,
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px',
      borderRightWidth: '1px',
    }
  }
  return styles;
};

export default getCellStyles;
