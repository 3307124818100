import { css } from "styled-components/macro";

export const LabelsNormal = css`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 136%;
`;

export const LabelsBold = css`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 136%;
`;

export const Captions1 = css`
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 100%;
`;

export const CaptionsBold1 = css`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 100%;
`;
