import { useMarketContext } from "@/context/MarketContext";
import { BASE_API_URL } from "@/utils/AppConfig";
import { getUiKitSymbol } from "@/utils/misc";
import useSWR, { Fetcher } from "swr";

type CollateralInfo = {
  tokens: string;
  supply: number;
  deposit_apy: number;
  maxLeverage: number;
  maxAPY: number;
  isActive: boolean;
};

type ResponseType = CollateralInfo[];

const fetcher: Fetcher<ResponseType, string> = (url: string) =>
  fetch(url).then((r) => r.json());

const ACTION = "assets_deposit_leverage";

export default function useCollaterals() {
  const { currentMarket } = useMarketContext();

  const API = `${BASE_API_URL}?action=${ACTION}&market=${currentMarket}`;

  const { data, isLoading, error } = useSWR(API, fetcher);

  const formattedData =
    data && !Object.keys(data).includes("error") ? data : [];

  const collateralData = formattedData
    ?.filter((ele) => ele.isActive)
    .map((ele) => {
      return {
        symbol: getUiKitSymbol(ele.tokens),
        supply: ele.supply || 0,
        depositAPY: ele.deposit_apy,
        maxLeverage: ele.maxLeverage,
        maxAPY: ele.maxAPY,
      };
    });

  return {
    collateralData,
    isLoading,
    error: error || Object.keys(data || []).includes("error"),
  };
}
