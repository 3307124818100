export const renameKeys = (
  keysMap: { [x: string]: string },
  obj: { [x: string]: any }
) =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...{ [keysMap[key] || key]: obj[key] },
    }),
    {}
  );

const SYMBOL_MAP: { [key: string]: string } = {
  CVXIRON_BANK: "IRON_BANK_LP",
  CVXDAI_USDC_USDT_SUSD: "DAI_USDC_USDT_SUSD_LP",
  CVXFRAX_USDC: "FRAX_USDC_LP",
  CVXFRAX_3CRV: "FRAX_3CRV_LP",
  CVXETH_STETH: "ETH_STETH_LP",
  CVXTUSD_FRAXBP: "TUSD_FRAXBP_LP",
  AURAWSTETH_WETH: "BAL_WSTETH_WETH_LP",
  CVXMIM_3CRV: "MIM_3CRV_LP",
};

export const getUiKitSymbol = (symbol: string) =>
  SYMBOL_MAP[symbol.toUpperCase()] !== undefined
    ? SYMBOL_MAP[symbol.toUpperCase()]
    : symbol;
