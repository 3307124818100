import { useState } from "react";
import { TableContainer, Table } from "@mui/material";
import buildTableComponents from "./buildTableComponents";
import { getSortedRows } from "./helpers";
import { HeadCell, RiskParams, Order } from "./types";

const headCells: readonly HeadCell[] = [
  {
    id: "symbol",
    numeric: false,
    disablePadding: true,
    label: "Token",
    sortable: false,
  },
  {
    id: "maxLTV",
    numeric: true,
    disablePadding: false,
    label: "Max LTV",
    sortable: true,
  },
  {
    id: "liquidationThreshold",
    numeric: true,
    disablePadding: false,
    label: "Liquidation Threshold",
    sortable: true,
  },
  {
    id: "liquidationPenalty",
    numeric: true,
    disablePadding: false,
    label: "Liquidation Penalty",
    sortable: true,
  },
  {
    id: "allowedContract",
    numeric: true,
    disablePadding: false,
    label: "Allowed Contract",
    sortable: false,
  },
];

interface RiskParamsTableProps {
  data: RiskParams[];
}

export default function RiskParamsTable({ data }: RiskParamsTableProps) {
  const { Head, Body } = buildTableComponents();
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof RiskParams>("maxLTV");

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof RiskParams
  ) => {
    const newOrder = orderBy === property && order === "asc" ? "desc" : "asc";
    const newOrderBy = property;

    setOrder(newOrder);
    setOrderBy(newOrderBy);
  };

  return (
    <TableContainer>
      <Table>
        <Head
          headCells={headCells}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <Body rows={getSortedRows(data, order, orderBy)} />
      </Table>
    </TableContainer>
  );
}
