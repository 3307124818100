import TableCell from "@mui/material/TableCell";
import getCellStyles from "./helpers";
import { RiskParams } from "../types";
import { TokenIcon, getAssetInfo } from "@sturdyfi/sturdy-ui-kit";
import { StyledCell, StyledTokenCell } from "./Styled";

interface CellProps {
  id: keyof RiskParams;
  index: number;
  cellValue: string;
}

export default function Cell({ id, index, cellValue }: CellProps) {
  const position =
    id === "symbol" ? "left" : id === "allowedContract" ? "right" : "middle";

  const asset = id === "symbol" ? getAssetInfo(cellValue) : null;
  const tokenName = asset
    ? !!asset.symbolsArray && asset.symbolsArray.length > 2
      ? asset.shortSymbol
      : cellValue
    : "";

  return (
    <TableCell
      key={`${id}-${index}-cell`}
      align={id === "symbol" ? "left" : "right"}
      style={getCellStyles(position)}
    >
      {id === "symbol" ? (
        <StyledTokenCell>
          <TokenIcon
            tokenSymbol={cellValue}
            // tokenFullName={cellValue.toUpperCase()}
            height={30}
            width={30}
          />
          {tokenName?.toUpperCase()}
        </StyledTokenCell>
      ) : (
        <StyledCell>{cellValue}</StyledCell>
      )}
    </TableCell>
  );
}
