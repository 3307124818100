import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { ArrowDropDown } from '@mui/icons-material';
import { HeadCell, Order, AssetData } from "../types";
import { StyledCell } from "./Styled";

interface HeadProps {
  headCells: readonly HeadCell[];
  order?: Order;
  orderBy?: string;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    newOrderBy: keyof AssetData
  ) => void;
}

export default function Head({
  headCells,
  order,
  orderBy,
  onRequestSort,
}: HeadProps) {
  const createSortHandler =
    (newOrderBy: keyof AssetData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sortable ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                IconComponent={ArrowDropDown}
              >
                <StyledCell>{headCell.label}</StyledCell>
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <StyledCell>{headCell.label}</StyledCell>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
