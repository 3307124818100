import { TableRow } from "@mui/material";
import { CollateralData } from "../types";
import Cell from "./Cell";
import { useIntl } from "react-intl";

interface RowProps {
  index: number;
  collateral: CollateralData;
}

export default function Row({ index, collateral }: RowProps) {
  const intl = useIntl();

  const formatValue = (id: keyof CollateralData, value: string | number) => {
    if (id === 'supply') {
      return intl.formatNumber(Number(value), {
        style: 'currency',
        currency: "USD",
        maximumFractionDigits: 2,
      });
    }

    if (id === 'maxLeverage') {
      return intl.formatNumber(Number(value), {
        maximumFractionDigits: 2,
      }) + 'x';
    }
    if (id === "depositAPY" || id === "maxAPY") {
      return intl.formatNumber(Number(value) / 100, {
        style: "percent",
        maximumFractionDigits: 2,
      });
    }
    return String(value);
  };
    
  return (
    <TableRow key={`${index}-row`}>
      {
        (Object.keys(collateral) as (keyof CollateralData)[]).map((k) => (
          <Cell key={k} id={k} index={index} cellValue={formatValue(k, collateral[k])} />
        ))
      } 
    </TableRow>
  );
}
