import { useIntl } from "react-intl";
import { Box, BoxProps, Skeleton, styled } from "@mui/material";
import { colors } from "@/utils/design-system";
import { LabelsNormal, CaptionsBold1 } from "@/utils/design-system/typography";

const StyledBox = styled(Box)<BoxProps>(({ theme }) => ({
  width: 222,
  boxSizing: "border-box",
  borderRadius: 8,
  boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
  borderWidth: 1,
  borderStyle: 'solid',
  gap: 8,
  padding: "16px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  background: theme.palette.mode === 'light' ? colors.base.white : colors.secondary[700],
  borderColor:theme.palette.mode === 'light' ? colors.greys[200] : colors.secondary[700],

}));

const StyledTitle = styled("p")(({ theme }) => ({
  LabelsNormal,
  flex: 1,
  color:
    theme.palette.mode === "light" ? colors.secondary[100] : colors.greys[600],
}));

const StyledValue = styled("span")(({ theme }) => ({
  CaptionsBold1,
  color:
    theme.palette.mode === "light" ? colors.secondary[800] : colors.greys[100],
}));

interface SummaryCardProps {
  title: string;
  value: number | string;
  isLoaing?: boolean;
}

export default function SummaryCard({
  title,
  value,
  isLoaing = false,
}: SummaryCardProps) {
  const intl = useIntl();
  return (
    <StyledBox>
      <StyledTitle>{title}</StyledTitle>
      {isLoaing ? (
        <Skeleton variant="rectangular" width={120} height={20} />
      ) : (
        <StyledValue>
          {intl.formatNumber(Number(value), {
            style: "currency",
            currency: "USD",
          })}
        </StyledValue>
      )}
    </StyledBox>
  );
}
