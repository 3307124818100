import styled from "styled-components";
import { Box } from "@mui/material";

// export const StyledBox = styled(({bgColor, borderColor, ...otherProps}) => <Box {...otherProps} />)`
export const StyledBox = styled(({bgColor, borderColor, ...otherProps}) => <Box {...otherProps} />)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: 8px;
  width: 100%;
  border-radius: 8px; 
  box-sizing: border-box;
  padding: 16px;
  background: ${props => props.bgColor};
  border: 1px solid ${props => props.borderColor};
`;

export const StyledLeftPanel = styled(({bgColor, borderColor, ...otherProps}) => <Box {...otherProps} />)`
  box-sizing: border-box;
  width: 168px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;

  border-radius: 8px;

  background: ${props => props.bgColor};
  border: 1px solid ${props => props.borderColor};
`;

export const StyledRightPanel = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px;
  gap: 8px;
`;
