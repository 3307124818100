import { TableBody } from "@mui/material";
import Row from "./Row";
import { CollateralData } from "../types";

interface BodyProps {
  rows: CollateralData[];
}

export default function Body({rows}: BodyProps) {
  return (
    <TableBody>
      {rows.map((rowData, index) => (
        <Row key={index} index={index} collateral={rowData} />
      ))}
    </TableBody>
  )
}