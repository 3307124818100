import { Chip, Avatar, Icon } from "@mui/material";
import { Circle, RemoveCircleOutline } from "@mui/icons-material";
import { pink, red } from "@mui/material/colors";

interface FilterChipProps {
  label: string;
  selected: boolean | undefined;
  handleClick: () => void;
  circleColor: string;
}

export default function FilterChip({
  label,
  selected,
  handleClick,
  circleColor,
}: FilterChipProps) {

  return (
    <Chip
      sx={{
        "& .MuiChip-icon": {
          color: circleColor,
        },
      }}
      icon={<Circle />}
      label={label}
      deleteIcon={
        selected ? (
          <RemoveCircleOutline />
        ) : (
          <Icon sx={{ width: 0, height: 0 }} />
        )
      }
      onClick={handleClick}
      onDelete={handleClick}
      // avatar={<Avatar sx={{ bgColor: red }} />}
    />
  );
}
