import React, { PropsWithChildren, useContext, useState } from "react";

export enum CustomMarket {
  proto_all = "all",
  proto_mainnet = "mainnet",
  proto_eth = "eth",
}

export interface MarketContextData {
  currentMarket: CustomMarket;
  setCurrentMarket: (market: CustomMarket) => void;
}

const MarketContext = React.createContext({} as MarketContextData);

const LS_KEY = "selectedMarket";

const getInitialMarket = () => {
  const cachedMarket = localStorage.getItem(LS_KEY) as CustomMarket | undefined;
  if (cachedMarket) return cachedMarket;
  return CustomMarket.proto_all;
};

export function MarketProvider({ children }: PropsWithChildren<{}>) {
  const [currentMarket, setCurrentMarket] = useState<CustomMarket>(
    getInitialMarket()
  );

  const handleSetMarket = (market: CustomMarket) => {
    localStorage.setItem(LS_KEY, market);
    setCurrentMarket(market);
  };

  return (
    <MarketContext.Provider
      value={{
        currentMarket,
        setCurrentMarket: handleSetMarket,
      }}
    >
      {children}
    </MarketContext.Provider>
  );
}

export const useMarketContext = () => useContext(MarketContext);
