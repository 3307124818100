import { Container, Box, Typography, CircularProgress } from "@mui/material";
import "@fontsource/poppins";
import "./App.css";
import "@sturdyfi/sturdy-ui-kit/dist/sturdy-ui-kit.cjs.development.css";
import SectionWrapper from "./components/SectionWrapper";
import AssetTable from "./components/Tables/AssetTable";
import MarketSwitch from "./components/MarketSwitch";

import useVolumn from "./hooks/useVolumn";
import useYield from "./hooks/useYield";
import useBorrowAPY from "./hooks/useBorrowAPY";
import useUser from "./hooks/useUser";
import useLiquidityIndex from "./hooks/useLiquidityIndex";
import useAssets from "./hooks/useAssets";
import CollateralTable from "./components/Tables/CollateralTable";
import useCollaterals from "./hooks/useCollaterals";
import useRiskParams from "./hooks/useRiskParams";
import RiskParamsTable from "./components/Tables/RiskParamsTable";
import Header from "./components/Header";
import useOverview from "./hooks/useOverview";
import SummaryCard from "./components/SummaryCard";
import Footer from "./components/Footer";
import ChartWrapper from "./components/ChartWrapper";
import useDepositAPY from "./hooks/useDepositAPY";
import useLiquidation from "./hooks/useLiquidation";

function App() {
  const { overviewData, isLoading: overviewLoading } = useOverview();
  const { volumeData, isLoading: volumeLoading } = useVolumn();
  const { assetData, isLoading: assetLoading } = useAssets();
  const { collateralData, isLoading: collateralLoading } = useCollaterals();
  // const { yieldData, isLoading: yieldLoading } = useYield();
  const { depositAPYData, isLoading: depositAPYLoading } = useDepositAPY();
  const { borrowAPYData, isLoading: borrowAPYLoading } = useBorrowAPY();
  const { userData, isLoading: userLoading } = useUser();
  const { liquidationEventData, isLoading: liquidationEventLoading } = useLiquidation();
  // const { liquidityIndexData, isLoading: liquidityIndexLoading } =
  //   useLiquidityIndex();

  const { riskParamsData, isLoading: riskParamsLoading } = useRiskParams();

  return (
    <div className="app">
      <Header />
      <Container sx={{ maxWidth: "desktop", mt: 7, py: 5, px: 4 }}>
        <Box sx={{ my: 4, mx: "auto" }}>
          <MarketSwitch />
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "stretch",
            my: 4,
          }}
        >
          {Object.keys(overviewData).map((key) => (
            <SummaryCard
              key={key}
              title={key}
              value={overviewData[key]}
              isLoaing={overviewLoading}
            />
          ))}
        </Box>

        <SectionWrapper title="historical volume" showVisibility={false}>
          {volumeLoading ? (
            <CircularProgress />
          ) : (
            <ChartWrapper
              chartName="volume"
              marketData={volumeData}
            />
          )}
        </SectionWrapper>

        <SectionWrapper title="assets to lend or borrow">
          {assetLoading ? (
            <CircularProgress />
          ) : (
            <AssetTable data={assetData} />
          )}
        </SectionWrapper>

        <SectionWrapper title="assets to deposit/leverage">
          {collateralLoading ? (
            <CircularProgress />
          ) : (
            <CollateralTable data={collateralData} />
          )}
        </SectionWrapper>

        {/* <SectionWrapper title="historical yield">
          {yieldLoading ? (
            <CircularProgress />
          ) : (
            <ChartWrapper
              chartName="yield"
              marketData={yieldData}
            />
          )}
        </SectionWrapper> */}
        <SectionWrapper title="historical lending APY">
          {depositAPYLoading ? (
            <CircularProgress />
          ) : (
            <ChartWrapper
              chartName="depositAPY"
              marketData={depositAPYData}
              chartFormat="percentage"
            />
          )}
        </SectionWrapper>

        <SectionWrapper title="historical borrowing APY">
          {borrowAPYLoading ? (
            <CircularProgress />
          ) : (
            <ChartWrapper
              chartName="borrowAPY"
              marketData={borrowAPYData}
              chartFormat="percentage"
            />
          )}
        </SectionWrapper>
        <SectionWrapper title="historical users">
          {userLoading ? (
            <CircularProgress />
          ) : (
            <ChartWrapper
              chartName="users"
              marketData={userData}
              chartFormat="number"
            />
          )}
        </SectionWrapper>

        <SectionWrapper title="historical liquidation events">
          {liquidationEventLoading ? (
            <CircularProgress />
          ) : (
            <ChartWrapper
              chartName="liquidation"
              marketData={liquidationEventData}
              chartFormat="number"
            />
          )}
        </SectionWrapper>

        <SectionWrapper title="risk parameter">
          {riskParamsLoading ? (
            <CircularProgress />
          ) : (
            <RiskParamsTable data={riskParamsData} />
          )}
        </SectionWrapper>
      </Container>
      <Footer />
    </div>
  );
}

export default App;
