import styled from "styled-components";
import { LabelsNormal, LabelsBold } from "@/utils/design-system/typography";

export const StyledCell = styled.span`
  ${LabelsNormal}
`;

export const StyledBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;


export const StyledSocialIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;
`;
